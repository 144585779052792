<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.title }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <div class="fullScreenMainCon">
        <el-form ref="form" :rules="formRules" :model="form">
          <el-descriptions border class="margin-top w_100" :column="8" direction="vertical">
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              label="单据编号"
              v-if="options.id"
            >
              <el-form-item prop="expenseNumber" style="width: 100%">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              label="申请人"
            >
              <el-form-item prop="createName" style="width: 100%">
                {{ form.createName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label"> 申请日期 </template>
              <el-form-item prop="taskDate" style="width: 100%">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="options.id ? 1 : 2"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开票日期
              </template>
              <el-form-item ref="invoiceDate" prop="invoiceDate" style="width: 100%">
                <span v-if="options.disabled">{{ form.invoiceDate | dateFormat }}</span>
                <el-date-picker
                  v-else
                  value-format="timestamp"
                  v-model="form.invoiceDate"
                  type="date"
                  :clearable="false"
                  placeholder="请选择开票日期"
                ></el-date-picker>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="1"
            >
              <template slot="label">
                <span style="color: red">*</span>
                销售方
              </template>
              <el-form-item ref="companyType" prop="companyType" style="width: 100%">
                <span v-if="options.disabled">{{
                  form.companyType | dict(dictData.companyType)
                }}</span>
                <Dictionary
                  v-else
                  :filterable="true"
                  :clearable="false"
                  v-model="form.companyType"
                  code="COMPANY_TYPE_FULL"
                  placeholder="请选择销售方"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                购买方
              </template>
              <el-form-item ref="customersUnit" prop="customersUnit" style="width: 100%">
                <span v-if="options.disabled">{{ form.customersUnit }}</span>
                <el-input v-else v-model="form.customersUnit" placeholder="请输入购买方"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                申请类型
              </template>
              <el-form-item
                ref="invoiceApplicationType"
                prop="invoiceApplicationType"
                style="width: 100%"
              >
                <span v-if="options.disabled">{{
                  form.invoiceApplicationType | dict(dictData.invoiceApplicationType)
                }}</span>
                <Dictionary
                  v-else
                  v-model="form.invoiceApplicationType"
                  code="INVOICE_APPLICATION_TYPE"
                  :clearable="false"
                  placeholder="请选择申请类型"
                  @change="onChangeType"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                纳税人识别号
              </template>
              <el-form-item ref="taxpayerNumber" prop="taxpayerNumber" style="width: 100%">
                <span v-if="options.disabled">{{ form.taxpayerNumber }}</span>
                <el-input
                  v-else
                  v-model="form.taxpayerNumber"
                  placeholder="请输入纳税人识别号"
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <template v-if="form.invoiceApplicationType != 'PROJECT_CONTRACT_PENDING'">
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                :span="
                  projectSourceRules
                    ? 6
                    : deputyGeneralRules && !agreementRules
                    ? 3
                    : deputyGeneralRules
                    ? 4
                    : 5
                "
              >
                <template slot="label">
                  <span style="color: red">{{ projectRules ? '*' : '' }}</span>
                  项目名称
                </template>
                <el-form-item ref="projectName" prop="projectName">
                  <span v-if="options.disabled">{{ form.projectName }}</span>
                  <ChoiceProject
                    v-else-if="
                      form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED' ||
                      form.invoiceApplicationType == 'APPROVED_CONTRACT_PENDING'
                    "
                    :options="form"
                    :applyType="form.invoiceApplicationType"
                    :projectSelsectId="projectSelsectId"
                    @choiceDataChange="choiceProject"
                  >
                    <el-input
                      slot="button"
                      :readonly="true"
                      style="cursor: pointer; margin-bottom: 8px"
                      v-model="form.projectName"
                      placeholder="请选择项目名称"
                    ></el-input>
                    <template slot="tableColumn-left">
                      <el-table-column
                        align="center"
                        prop="projectName"
                        :show-overflow-tooltip="false"
                        min-width="320"
                        label="项目名称"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        min-width="220"
                        :show-overflow-tooltip="false"
                        prop="customersUnit"
                        label="业主单位"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        prop="theirDeptName"
                        label="所属部门"
                      ></el-table-column>
                    </template>
                  </ChoiceProject>
                  <el-input
                    v-else
                    v-model="form.projectName"
                    placeholder="请输入项目名称"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>

              <!-- 不立项不补签合同、有合同未立项（不需要补立项） -->
              <template v-if="!projectSourceRules">
                <el-descriptions-item
                  labelClassName="invoiceLabelClassName"
                  contentClassName="invoiceContentClassName"
                >
                  <template slot="label"> 项目分管高管 </template>
                  <el-form-item ref="fzManagerName" prop="fzManagerName">
                    {{ form.fzManagerName }}
                  </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item
                  labelClassName="invoiceLabelClassName"
                  contentClassName="invoiceContentClassName"
                >
                  <template slot="label"> 项目所属部门 </template>
                  <el-form-item ref="deptClassifyName" prop="deptId" style="width: 100%">
                    {{ form.deptClassifyName }}
                  </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item
                  labelClassName="invoiceLabelClassName"
                  contentClassName="invoiceContentClassName"
                >
                  <template slot="label"> 项目来源 </template>
                  <el-form-item ref="projectSource" prop="projectSource">
                    {{ form.projectSource | dict(dictData.projectSource) }}
                  </el-form-item>
                </el-descriptions-item>
              </template>
            </template>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="deputyGeneralRules"
              :span="
                form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING'
                  ? 4
                  : deputyGeneralRules
                  ? 1
                  : 1
              "
            >
              <template slot="label">
                <span style="color: red">{{ deputyGeneralRules ? '*' : '' }}</span
                >分管副总
              </template>
              <el-form-item ref="generalManagerName" prop="generalManagerName">
                <span v-if="options.disabled">{{ form.generalManagerName }}</span>
                <SelectDialog
                  v-else
                  :userType="'GENERAL_MANAGER'"
                  @selectDataChange="generalManagerNameFn"
                >
                  <el-input
                    slot="button"
                    style="cursor: pointer"
                    v-model="form.generalManagerName"
                    placeholder="请选择分管副总"
                  ></el-input>
                </SelectDialog>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="deputyGeneralRules"
              :span="form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING' ? 4 : 1"
            >
              <template slot="label">
                <span style="color: red">{{ deputyGeneralRules ? '*' : '' }}</span
                >股东层
              </template>
              <el-form-item ref="shareholderName" prop="shareholderName">
                <span v-if="options.disabled">{{ form.shareholderName }}</span>
                <SelectDialog
                  v-else
                  :userType="'SHAREHOLDER'"
                  @selectDataChange="shareholderNameFn"
                >
                  <el-input
                    slot="button"
                    style="cursor: pointer"
                    v-model="form.shareholderName"
                    placeholder="请选择股东层"
                  ></el-input>
                </SelectDialog>
              </el-form-item>
            </el-descriptions-item>

            <!-- 不立项不补签合同/已立项不签合同（不需要补合同）/已立项未签合同（需要补合同） -->
            <template
              v-if="
                form.invoiceApplicationType != 'NO_PROJECT_NO_CONTRACT_NEEDED' &&
                form.invoiceApplicationType != 'APPROVED_NO_CONTRACT_NEEDED' &&
                form.invoiceApplicationType != 'PROJECT_CONTRACT_PENDING' &&
                form.invoiceApplicationType != 'APPROVED_CONTRACT_PENDING'
              "
            >
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                :span="agreementRules ? 4 : 5"
              >
                <template slot="label">
                  <span style="color: red">{{ agreementRules ? '*' : '' }}</span>
                  合同名称
                </template>

                <el-form-item ref="agreementName" prop="agreementName">
                  <span v-if="options.disabled">{{ form.agreementName }}</span>

                  <ChoiceContract
                    v-else-if="agreementRules"
                    :options="form"
                    :introduce="'notInvoice'"
                    :agreementSelsectId="agreementSelsectId"
                    @choiceDataChange="choiceContract"
                  >
                    <el-input
                      slot="button"
                      :readonly="true"
                      style="cursor: pointer; margin-bottom: 8px"
                      v-model="form.agreementName"
                      placeholder="请选择合同名称"
                    ></el-input>

                    <template slot="tableColumn-left">
                      <el-table-column
                        align="center"
                        prop="agreementName"
                        min-width="320"
                        :show-overflow-tooltip="false"
                        label="合同名称"
                      ></el-table-column>
                      <el-table-column
                        align="center"
                        min-width="220"
                        :show-overflow-tooltip="false"
                        prop="customersUnit"
                        label="业主单位"
                      ></el-table-column>
                    </template>
                  </ChoiceContract>
                  <el-input
                    v-else
                    v-model="form.agreementName"
                    placeholder="请输入合同名称"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
              >
                <template slot="label"> 合同编号 </template>
                <el-form-item ref="agreementNum" prop="agreementNum">
                  {{ form.agreementNum }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
              >
                <template slot="label"> 合同总金额 （元） </template>
                <el-form-item ref="actualContractAmount" prop="actualContractAmount">
                  {{ form.actualContractAmount | applyAmount }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                :span="1"
              >
                <template slot="label">我方实际金额（元） </template>
                <el-form-item ref="ourActualAmount" prop="ourActualAmount">
                  <span v-if="options.disabled">{{ form.ourActualAmount | applyAmount }}</span>
                  <el-input
                    type="number"
                    class="numrule"
                    @blur="blurAmount('ourActualAmount')"
                    v-else
                    v-model="form.ourActualAmount"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="invoiceLabelClassName"
                contentClassName="invoiceContentClassName"
                v-if="agreementRules"
              >
                <template slot="label"> 合同待收金额（元） </template>
                <el-form-item ref="contractPendingAmount" prop="contractPendingAmount">
                  <span v-if="options.disabled">
                    {{ form.contractPendingAmount | applyAmount }}</span
                  >
                  <el-input
                    type="number"
                    class="numrule"
                    @blur="blurAmount('contractPendingAmount')"
                    v-else
                    v-model="form.contractPendingAmount"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
            </template>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span>发票种类</span>
                <el-button
                  v-show="form.invoiceLines === 'OFFSET' && !options.disabled"
                  size="mini"
                  type="warning"
                  style="margin-left: 5px"
                  @click="isInvoiceShow = true"
                >
                  选择冲红发票
                </el-button>
              </template>
              <el-form-item ref="invoiceLines" prop="invoiceLines">
                <span v-if="options.disabled">{{
                  form.invoiceLines | dict(dictData.invoiceLines)
                }}</span>
                <Dictionary
                  v-else
                  v-model="form.invoiceLines"
                  code="INVOICE_LINES"
                  :clearable="false"
                  @change="onChangeInvoiceLines"
                  placeholder="请选择发票种类"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="form.status == 100 && options.disabled && form.offsetStatus == 20"
            >
              <template slot="label">
                <span>发票状态</span>
              </template>
              <el-form-item ref="offsetStatus" prop="offsetStatus">
                <span>{{ form.offsetStatus | dict(dictData.offsetStatus) }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="
                (form.invoiceOffse && options.disabled) ||
                (form.invoiceLines === 'OFFSET' && offsetId)
              "
            >
              <template slot="label">
                <span>冲红单据编号</span>
                <el-button
                  size="mini"
                  type="primary"
                  style="margin-left: 5px"
                  @click="viewInvoiceShow = true"
                >
                  查看详情
                </el-button>
              </template>
              <el-form-item ref="" prop="">
                <span v-if="form.invoiceOffse">{{ form.invoiceOffse.expenseNumber }}</span>
                <span v-else>{{ expenseNumber }}</span>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开具发票类型
              </template>
              <el-form-item prop="invoiceType" ref="invoiceType">
                <span v-if="options.disabled">{{
                  form.invoiceType | dict(dictData.invoiceType)
                }}</span>
                <Dictionary
                  v-else
                  v-model="form.invoiceType"
                  code="INVOICE_TYPE"
                  :clearable="false"
                  :disabled="options.disabled"
                  placeholder="请选择开具发票类型"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="
                (form.invoiceOffse && options.disabled) ||
                (form.invoiceLines === 'OFFSET' && offsetId)
                  ? 1
                  : 2
              "
            >
              <template slot="label">
                <span style="color: red">*</span>
                增值税类型
              </template>
              <el-form-item prop="vatType" ref="vatType">
                <span v-if="options.disabled"> {{ form.vatType | transitionType(zzsList) }}</span>
                <el-select
                  v-else
                  :disabled="false"
                  v-model="form.vatType"
                  @change="optionsChangeType"
                  placeholder="请选择增值税类型"
                  class="ipt_width"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in zzsList"
                    :key="item.id"
                    :label="item.vatName"
                    :value="item.vatType"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="form.status == 100 && options.disabled && form.offsetStatus == 20 ? 1 : 2"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开票内容
              </template>
              <el-form-item prop="vatData" ref="vatData">
                <span v-if="options.disabled">
                  {{ form.vatData | transitionData(dictData.VatDataAll) }}</span
                >
                <VatDictionary
                  v-else
                  style="width: 100%"
                  valType="Data"
                  :disabled="false"
                  v-model="form.vatData"
                  :clearable="false"
                  :code="form.vatType"
                  placeholder="请选择开票内容"
                />
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="2"
            >
              <template slot="label">
                <span style="color: red">*</span>
                增值税率（%）
              </template>
              <el-form-item prop="vatRate" ref="vatRate">
                <span v-if="options.disabled"> {{ form.vatRate }}</span>
                <VatDictionary
                  v-else
                  style="width: 100%"
                  valType="TaxRate"
                  :disabled="false"
                  v-model="form.vatRate"
                  :code="form.vatData"
                  :clearable="false"
                  @optionsChange="optionsChange"
                  placeholder="请选择增值税税率"
                />
              </el-form-item>
            </el-descriptions-item>
            <!-- <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              v-if="options.id"
              :span="1"
              label="是否优先发放"
            >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="1"
                >是</el-radio
              >
              <el-radio :disabled="!form.isDisplayEditButton" v-model="form.priority" :label="0"
                >否</el-radio
              >
            </el-descriptions-item> -->
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="5"
            >
              <template slot="label">
                <span
                  style="color: red"
                  v-show="form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao'"
                  >*</span
                >
                地址
              </template>
              <el-form-item prop="address" :rules="formRules.address" ref="address">
                <span v-if="options.disabled">{{ form.address }}</span>
                <el-input v-else v-model="form.address" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="3"
            >
              <template slot="label">
                <span
                  style="color: red"
                  v-show="form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao'"
                  >*</span
                >
                电话
              </template>
              <el-form-item prop="phone" :rules="formRules.phone" ref="phone">
                <span v-if="options.disabled">{{ form.phone }}</span>
                <el-input v-else v-model="form.phone" placeholder="电话"></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="5"
            >
              <template slot="label">
                <span
                  style="color: red"
                  v-show="form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao'"
                  >*</span
                >
                开户行
              </template>
              <el-form-item prop="openingBank" :rules="formRules.openingBank" ref="openingBank">
                <span v-if="options.disabled">{{ form.openingBank }}</span>
                <el-input v-else v-model="form.openingBank" placeholder="请输入开户行"> </el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="3"
            >
              <template slot="label">
                <span
                  style="color: red"
                  v-show="form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao'"
                  >*</span
                >
                账号
              </template>
              <el-form-item prop="account" :rules="formRules.account" ref="account">
                <span v-if="options.disabled">{{ form.account }}</span>
                <el-input
                  v-else
                  @input="accountAddBlank('account')"
                  v-model="form.account"
                  placeholder="请输入账号"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="8"
            >
              <template slot="label">
                <span style="color: red">*</span>
                发票备注（按甲方要求填写开票备注信息，如甲方无要求，则填写“无”）
              </template>
              <el-form-item ref="invoiceRemark" prop="invoiceRemark" style="width: 100%">
                <span v-if="options.disabled">{{ form.invoiceRemark }}</span>
                <el-input
                  v-else
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 99 }"
                  v-model="form.invoiceRemark"
                  placeholder="请输入发票备注"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="invoiceLabelClassName"
              contentClassName="invoiceContentClassName"
              :span="8"
              v-if="specialExplainRules"
            >
              <template slot="label">
                <span style="color: red">*</span>
                特殊情况说明 {{ specialExplain }}
              </template>
              <el-form-item
                ref="specialSituationDesc"
                prop="specialSituationDesc"
                style="width: 100%"
              >
                <span v-if="options.disabled">{{ form.specialSituationDesc }}</span>
                <el-input
                  v-else
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 99 }"
                  v-model="form.specialSituationDesc"
                  placeholder="请输入特殊情况说明"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <el-descriptions v-if="form.status == 100" title="" :column="2" border direction="vertical">
          <el-descriptions-item label="发票编号">{{ form.invoicingNum }}</el-descriptions-item>
          <el-descriptions-item label="财务开票备注">{{ form.remark }}</el-descriptions-item>
          <el-descriptions-item label="附件">
            <span
              v-for="(item, index) in form.affirmCostFileList"
              :key="index"
              class="invoice-file-text"
            >
              <a
                v-if="isShowType(item)"
                class="fj-text"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${item.filePath}`"
              >
                {{ item.fileName }}
              </a>

              <span v-else class="img_txt" @click="imgIsShow(item, index)">{{
                item.fileName
              }}</span>
            </span>
          </el-descriptions-item>
        </el-descriptions>

        <div class="ticket_box">
          <div class="ticket_div">
            <p>
              <!-- 本项目开票共
                <span>{{ form.periods }}</span>
                期， 本次申请开具第
                {{ form.currentPeriods }}
                次 -->
              <el-button
                v-if="agreementRules"
                class="jilvBtn"
                type="success"
                size="small"
                @click="historyIsShow"
              >
                历史开票记录
              </el-button>
              <el-button
                v-if="!options.disabled"
                class="lineBtn"
                type="primary"
                icon="el-icon-plus"
                size="small"
                @click="handleAdd"
              >
                添加期
              </el-button>
            </p>
          </div>
          <el-table ref="multipleTable" border :data="form.invoiceApplicationDetailList">
            <el-table-column fixed="left" label="序号" type="index" align="center" width="80">
            </el-table-column>
            <el-table-column
              prop="stageName"
              fixed="left"
              label="阶段名称"
              min-width="200"
              align="center"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    form.invoiceApplicationType != 'CONTRACT_SIGNED_NO_PROJECT_NEEDED' &&
                    !options.disabled
                  "
                >
                  <el-input v-model="scope.row.stageName"></el-input>
                  <div v-show="!scope.row.stageName" style="color: red">阶段名称不能为空</div>
                </template>
                <span v-else> {{ scope.row.stageName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="paymentAmount" label="本期金额" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.paymentAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="invoiceAmount"
              :show-overflow-tooltip="false"
              label="开票金额"
              min-width="110"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="options.disabled">{{ scope.row.invoiceAmount | applyAmount }}</div>
                <el-input
                  type="number"
                  class="numrule"
                  @blur="blurInput(scope.$index)"
                  v-else
                  v-model="scope.row.invoiceAmount"
                ></el-input>
                <div
                  v-show="form.invoiceLines === 'NORMAL' && Number(scope.row.invoiceAmount) === 0"
                  style="color: red"
                >
                  开票金额不能为0
                </div>
                <div
                  v-show="form.invoiceLines === 'OFFSET' && Number(scope.row.invoiceAmount) >= 0"
                  style="color: red"
                >
                  当前开票种类为红字发票（冲红），金额必须为负数
                </div>
              </template>
            </el-table-column>
            <!-- 流程结束才能看到 -->
            <template v-if="form.status == 100">
              <el-table-column prop="vatType" label="增值税类型" min-width="320" align="center">
                <template slot-scope="scope">
                  {{ scope.row.vatType | transitionType(zzsList) }}
                </template>
              </el-table-column>
              <el-table-column prop="VatData" label="开票内容" min-width="320" align="center">
                <template slot-scope="scope">
                  {{ scope.row.vatData | transitionData(dictData.VatDataAll) }}
                </template>
              </el-table-column>
              <el-table-column prop="vatRate" label="增值税税率" min-width="110" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.vatRate"> {{ scope.row.vatRate }} %</span>
                </template>
              </el-table-column>
              <el-table-column prop="vatFee" label="增值税" min-width="110" align="center">
                <template slot-scope="scope">
                  {{ scope.row.vatFee | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column
                prop="additionalTaxFee"
                label="附加税"
                min-width="110"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.additionalTaxFee | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column prop="stampTaxFee" label="印花税" min-width="110" align="center">
                <template slot-scope="scope">
                  {{ scope.row.stampTaxFee | applyAmount }}
                </template>
              </el-table-column>
              <el-table-column
                prop="taxesFee"
                fixed="right"
                min-width="110"
                label="总税费"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.row.taxesFee | applyAmount }}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              label="操作"
              v-if="!options.disabled && form.status != 100"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click.stop="deleteDetails(scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-descriptions title="" :column="2" border>
            <el-descriptions-item label="合计" labelStyle="width:10%">
              {{ amount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:10%">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <!-- 意见区 -->
        <OpinionArea
          ref="OpinionArea"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
          :title="'发票申请进度'"
        ></OpinionArea>
      </div>
      <div class="fullScreenOperation shadow">
        <el-button
          v-if="options.isApply && form.status != 10"
          type="success"
          class="teal_bg"
          @click="handleSubmit(1)"
          >提交</el-button
        >
        <el-button
          v-if="options.isApply && form.status != 10"
          type="success"
          @click="handleSubmit(0)"
          >{{ form.id ? '保存' : '暂存' }}</el-button
        >
        <el-button
          v-if="options.isGrant && options.status != 100"
          type="primary"
          @click="invoiceShow = true"
          >确认开票</el-button
        >
        <el-button type="success" v-if="options.isExamine" @click="handleAdopt">通过</el-button>
        <SelectDialog
          v-if="options.isExamine && form.status == 10 && permission(['FLOW_HAND_OVER'])"
          @selectDataChange="selectAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary"> 转审 </el-button>
        </SelectDialog>
        <el-button
          type="danger"
          v-if="options.isExamine || (options.isGrant && options.status != 100)"
          @click="handleFail"
          >不通过</el-button
        >
        <SelectDialog
          v-if="options.isAddAuditor && form.status == 10 && permission(['ADD_PUBLIC_AGENT'])"
          @selectDataChange="selectAddAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary"> 增加待办人 </el-button>
        </SelectDialog>
        <el-button v-if="options.isReject && form.status == 10" type="success" @click="rejectFn"
          >撤回</el-button
        >
        <el-button type="warning" v-if="options.isPrint && form.status == 100" @click="printFn"
          >预览打印</el-button
        >
        <el-button v-if="options.isExport" type="success" @click="exportFn">导出</el-button>
        <el-button
          v-if="
            options.isRetroactive &&
            form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING' &&
            form.status == 100
          "
          type="primary"
          @click="onRetroactive('editUnsignedContracts')"
          >补立项与合同</el-button
        >
        <el-button
          v-if="
            options.isRetroactive &&
            form.invoiceApplicationType == 'APPROVED_CONTRACT_PENDING' &&
            form.status == 100
          "
          type="primary"
          @click="onRetroactive('convertProjectToContractSigned')"
          >补合同</el-button
        >

        <el-button type="info" plain @click="re">返回</el-button>
      </div>
    </div>
    <EditDialog
      width="70%"
      :isShow.sync="agreement_show"
      :saveShow="false"
      @submit="agreement_show = false"
    >
      <div slot="title" class="title">选择合同期数</div>
      <div slot="content" class="content">
        <el-form class="column3 form_box table" label-position="top" style="width: 100%">
          <el-table ref="multipleTable" border :data="agreementStageList">
            <el-table-column type="index" width="70" label="序号" align="center"> </el-table-column>
            <el-table-column prop="stageName" label="阶段" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.stageName }}
              </template>
            </el-table-column>
            <el-table-column prop="paymentStandard" label="内容" min-width="500" align="center">
              <template slot-scope="scope">
                {{ scope.row.paymentStandard }}
              </template>
            </el-table-column>
            <el-table-column prop="paymentAmount" label="金额" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.paymentAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="proceedsDate" label="付款时间" min-width="110" align="center">
              <template slot-scope="scope">
                {{ scope.row.proceedsDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120" fixed="right" align="center">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="scope.row.Selected == 1"
                  @click="checkPhase(scope.row, scope.$index)"
                >
                  {{ scope.row.Selected == 1 ? '已选' : '选择' }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
    </EditDialog>

    <EditDialog :isShow.sync="isDialog">
      <template v-slot:title>
        <span style="color: #409eff">发票审批</span>
      </template>
      <template v-slot:content>
        <el-form
          class="column3"
          label-width="6em"
          ref="ruleForm"
          :model="examineForm"
          :rules="rules"
        >
          <el-form-item v-if="examineForm.status == 1" label="审核意见" style="width: 100%">
            <el-input v-model="examineForm.opinion" type="textarea" placeholder="通过"></el-input>
          </el-form-item>
          <el-form-item v-else label="审核意见" prop="opinion" style="width: 100%">
            <el-input
              v-model="examineForm.opinion"
              type="textarea"
              placeholder="请输入原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button
          :type="examineForm.status == 1 ? 'success' : 'danger'"
          @click="callApproval(examineForm.status)"
          >{{ examineForm.status == 1 ? '审批通过' : '审批不通过' }}</el-button
        >
        <el-button type="info" plain @click="cancelFn">取消</el-button>
      </template>
    </EditDialog>

    <!-- 开票弹窗 -->
    <InvoiceDialog
      v-if="invoiceShow"
      :isShow.sync="invoiceShow"
      :options="form"
      :introduce="'notInvoice'"
      @invoiceDetailsFn="re()"
    />

    <!-- 图片预览 -->
    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    />

    <!-- 打印 -->
    <PrintDialog
      v-if="printDiaLog"
      :printDiaLog.sync="printDiaLog"
      :form="printForm"
      :introduce="'notInvoice'"
      :examineInfo="examineInfo"
      :vatArr="dictData.VatDataAll"
      :companyType="dictData.companyType"
      :projectSourceArr="dictData.projectSource"
      :invoiceType="dictData.invoiceType"
    />

    <!-- 历史开票信息 -->
    <HistoryInvoiceDialog
      v-if="history_show"
      :history_show.sync="history_show"
      :zzsList="zzsList"
      :form="form"
      :vatArr="dictData.VatDataAll"
      :companyType="dictData.companyType"
      :historyDataList="historyDataList"
    />

    <!-- 冲红 -->
    <OffsetDialog
      v-if="isInvoiceShow"
      :isShow.sync="isInvoiceShow"
      @onCancelInvoice="onCancelInvoice"
      @onCoverInvoice="onCoverInvoice"
      @offsetCancel="isInvoiceShow = false"
      :offsetId="offsetId"
      :isUninitiatedProjectInvoice="true"
    />

    <!--关联详情 -->
    <ViewNotInvoice
      v-if="viewInvoiceShow"
      :isShow.sync="viewInvoiceShow"
      @offsetCancel="viewInvoiceShow = false"
      :offsetId="viewInvoiceId"
    />
    <!-- 提交意见 -->
    <SubmitOpinionDialog
      v-if="submitDialog"
      :isShow.sync="submitDialog"
      @offsetCancel="submitDialog = false"
      @submitOpinionFn="submitOpinionFn"
    />

    <!-- 补签项目与合同 -->
    <NotRetroactiveDialog
      v-if="isRetroactiveShow"
      :isShow.sync="isRetroactiveShow"
      :options="form"
      :applyType="applyType"
      @invoiceDetailsFn="re()"
    />
  </div>
</template>

<script>
import { formLocationError } from '@/util/common'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    ChoiceData: () => import('@/components/ChoiceData.vue'),
    ChoiceContract: () => import('@/components/invoice/ChoiceContract.vue'),
    ChoiceProject: () => import('@/components/invoice/ChoiceProject.vue'),
    InvoiceDialog: () => import('@/components/invoice/invoiceDialog.vue'),
    HistoryInvoiceDialog: () => import('@/components/invoice/historyInvoiceDialog.vue'),
    NotRetroactiveDialog: () => import('@/components/invoice/notRetroactiveDialog.vue'),
    PrintDialog: () => import('@/components/invoice/printDialog.vue'),
    OffsetDialog: () => import('@/components/invoice/offsetDialog.vue'),
    ViewNotInvoice: () => import('@/components/invoice/viewNotInvoice.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    SubmitOpinionDialog: () => import('@/components/submit-opinion-dialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {
          title: '', //面包屑
          isExamine: false, //审核
          isExport: false, //导出
          isPrint: false, //打印
          disabled: false, //编辑
          isApply: false, //新增
          isReject: false, //撤回
          isGrant: false, //发放
          isReturn: false, //归还
          isAddAuditor: false, //新增待办人
          isRetroactive: false, //补签
        }
      },
    },
    getProjectList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      showEditDialog: false, // 组件 隐藏 或 显示
      loading: false, // 组件 加载
      // 申请 表单数据
      form: {},
      // 审批 表单数据
      examineForm: {
        opinion: '',
        status: 0,
      },
      examineList: [], // 审核 列表数据
      // 表单 验证
      formRules: {
        projectName: [
          {
            required: true,
            message: '请输入项目名称',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (!this.projectRules) {
                callback()
              } else {
                if (!value) {
                  callback(new Error('请输入项目名称'))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        agreementName: [
          {
            required: true,
            message: '请输入合同名称',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (!this.agreementRules) {
                callback()
              } else {
                if (!value) {
                  callback(new Error('请输入合同名称'))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        specialSituationDesc: [
          {
            required: true,
            message: '请输入特殊情况说明',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (!this.specialExplainRules) {
                callback()
              } else {
                if (!value) {
                  callback(new Error('请输入特殊情况说明'))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        generalManagerName: [
          {
            required: true,
            message: '请选择分管副总',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (!this.deputyGeneralRules) {
                callback()
              } else {
                if (!value) {
                  callback(new Error('请选择分管副总'))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        shareholderName: [
          {
            required: true,
            message: '请选择股东层',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (!this.deputyGeneralRules) {
                callback()
              } else {
                if (!value) {
                  callback(new Error('请选择股东层'))
                } else {
                  callback()
                }
              }
            },
          },
        ],
        companyType: [
          {
            required: true,
            message: '请输入销售方',
            trigger: ['bulr', 'change'],
          },
        ],
        customersUnit: [
          {
            required: true,
            message: '请输入购买方',
            trigger: ['bulr', 'change'],
          },
        ],
        taxpayerNumber: [
          {
            required: true,
            message: '请输入纳税人识别号',
            trigger: ['bulr', 'change'],
          },
        ],
        invoiceType: [
          {
            required: true,
            message: '请选择开具发票类型',
            trigger: ['bulr', 'change'],
          },
        ],
        invoiceApplicationType: [
          {
            required: true,
            message: '请选择申请类型',
            trigger: ['bulr', 'change'],
          },
        ],
        vatType: [
          {
            required: true,
            message: '请选择增值税类型',
            trigger: ['bulr', 'change'],
          },
        ],
        vatData: [
          {
            required: true,
            message: '请选择开票内容',
            trigger: ['bulr', 'change'],
          },
        ],
        invoiceRemark: [
          {
            required: true,
            message: '请输入发票备注',
            trigger: ['bulr', 'change'],
          },
        ],
        invoiceDate: [
          {
            required: true,
            message: '请选择开票日期',
            trigger: ['bulr', 'change'],
          },
        ],
        address: [
          {
            required: true,
            message: '请输入地址',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (this.form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao') {
                if (!value) {
                  callback(new Error('请输入地址'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入正确的联系电话',
            trigger: ['bulr', 'change'],
            validator: (rule, value, callback) => {
              if (this.form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao') {
                if (!value) {
                  callback(new Error('请输入正确的联系电话'))
                } else {
                  const reg =
                    /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/ //通用手机号码正则表达式
                  value = value.replace(/\s/g, '')
                  if (reg.test(value)) {
                    callback()
                  } else {
                    return callback(new Error('请输入正确的联系电话'))
                  }
                }
              } else {
                if (value) {
                  const reg =
                    /^(((\d{3,4}-)?[0-9]{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/ //通用手机号码正则表达式
                  value = value.replace(/\s/g, '')
                  if (reg.test(value)) {
                    callback()
                  } else {
                    return callback(new Error('请输入正确的联系电话'))
                  }
                } else {
                  callback()
                }
              }
            },
          },
        ],
        openingBank: [
          {
            required: true,
            message: '请输入开户行',
            trigger: ['bulr', 'change'],
            // 自定义校验，根据特定值判断是否需要校验
            validator: (rule, value, callback) => {
              if (this.form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao') {
                if (!value) {
                  callback(new Error('请输入开户行'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
        account: [
          {
            required: true,
            message: '请输入正确的银行账号',
            trigger: ['bulr', 'change'],
            // 自定义校验，根据特定值判断是否需要校验
            validator: (rule, value, callback) => {
              if (this.form.invoiceType == 'zeng_zhi_shui_dian_zi_zhuan_yong_fa_piao') {
                if (!value) {
                  callback(new Error('请输入正确的银行账号'))
                } else {
                  const reg = /^\d{7,30}$/ //通用银行账号正则表达式
                  value = value.replace(/\s/g, '')
                  if (reg.test(value)) {
                    callback()
                  } else {
                    return callback(new Error('请输入正确的银行账号'))
                  }
                }
              } else {
                if (value) {
                  const reg = /^\d{7,30}$/ //通用银行账号正则表达式
                  value = value.replace(/\s/g, '')
                  if (reg.test(value)) {
                    callback()
                  } else {
                    return callback(new Error('请输入正确的银行账号'))
                  }
                } else {
                  callback()
                }
              }
            },
          },
        ],
      },
      rules: {
        opinion: [
          {
            required: true,
            message: '请输入审核意见',
            trigger: 'blur',
          },
        ],
      },
      history_show: false, //历史开票弹窗
      historyDataList: [],
      dictData: {
        invoiceType: [],
        VatDataAll: [],
        projectSource: [],
        companyType: [],
        invoiceLines: [],
        offsetStatus: [],
        invoiceApplicationType: [],
      },
      zzsList: [], //增值税类型
      agreementStageList: [], //合同期数数组
      agreementStageSelectedList: [], //已选
      agreement_show: false,

      isDialog: false, //审批弹窗
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'fileType',
      },
      type: 'FPSQFJ',
      instInvolved: {}, //当前进度处理人数组
      invoiceShow: false, //开票弹窗
      img_index: null, //图片预览下标
      imgList: [],
      dialogVisible: false,
      printDiaLog: false, //打印
      examineInfo: {}, //打印审批
      printForm: {},
      isInvoiceShow: false, //冲红弹窗
      offsetId: null, //冲红选中
      expenseNumber: null,
      projectSelsectId: null, //项目选中
      agreementSelsectId: null, //合同选中
      viewInvoiceShow: false, //查看关联单据
      submitDialog: false, //提交意见弹窗
      isRetroactiveShow: false,
      applyType: '',
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler: function (options) {
        if (options.id) {
          this.getDetails() /** 加载 "发票申请" 详情数据 **/
        } else {
          this.form = {
            createName: JSON.parse(localStorage.getItem('userInfo')).userName,
            taskDate: Date.now(),
            invoiceDate: null,
            companyType: null,
            customersUnit: null,
            taxpayerNumber: null,
            projectName: null,
            projectId: null,
            fzManagerName: null,
            deptFzManagerId: null,
            deptClassifyName: null,
            deptClassifyId: null,
            projectSource: null,
            agreementName: null,
            agreementId: null,
            agreementNum: null,
            actualContractAmount: null,
            ourActualAmount: null,
            invoiceType: null,
            vatType: null,
            vatData: null,
            vatRate: null,
            address: null,
            phone: null,
            openingBank: null,
            account: null,
            invoiceRemark: null,
            // periods: 0,
            // currentPeriods: 0,
            projectType: null,
            contractPendingAmount: null,
            generalManagerId: null,
            generalManagerName: null,
            invoiceApplicationType: null,
            shareholderId: null,
            shareholderName: null,
            specialSituationDesc: null,
            invoiceLines: 'NORMAL',
            detailDeleteList: [],
            invoiceApplicationDetailList: [],
          }
        }
      },
    },
    isDialog: {
      // immediate: true,
      handler: function (newVal) {
        if (!newVal) {
          this.cancelFn()
        }
      },
    },
  },
  created() {
    this.getDict()
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.invoiceApplicationDetailList) {
        this.form.invoiceApplicationDetailList.forEach(v => {
          if (v.invoiceAmount) {
            num += Number(v.invoiceAmount)
          }
        })
      }
      num = num.toFixed(2)
      return num
    },
    viewInvoiceId() {
      let id = ''
      if (this.form.invoiceOffse) {
        id = this.form.invoiceOffse.id
      } else {
        if (this.form.invoiceLines === 'OFFSET' && this.offsetId) {
          id = this.offsetId
        }
      }
      return id
    },
    // 项目校验
    projectRules() {
      /*
       *   已立项未签合同（需要补合同）
       *   已立项不签合同（不需要补合同）
       *   不补立项不补签合同
       */
      if (
        this.form.invoiceApplicationType == 'APPROVED_CONTRACT_PENDING' ||
        this.form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED'
      ) {
        return true
      } else {
        return false
      }
    },
    // 合同校验
    agreementRules() {
      // 有合同未立项（不需要补立项）
      if (this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED') {
        return true
      } else {
        return false
      }
    },
    // 特殊情况说明
    specialExplain() {
      if (this.form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED') {
        return '（说明不签合同的原因。）'
      } else if (this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED') {
        return '（项目所属年份、项目负责人、不签合同原因。）'
      } else if (this.form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING') {
        return '（说明补立项情况、合同预计上传时间、项目负责人。）'
      } else {
        return ''
      }
    },
    specialExplainRules() {
      /*
       *   已立项不签合同（不需要补合同）
       *   不补立项不补签合同
       *   未立项未签合同（需要后补项目和合同）
       */
      if (
        this.form.invoiceApplicationType == 'APPROVED_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING'
      ) {
        return true
      } else {
        return false
      }
    },
    deputyGeneralRules() {
      if (
        this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED' ||
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'PROJECT_CONTRACT_PENDING'
      ) {
        return true
      } else {
        return false
      }
    },
    projectSourceRules() {
      if (
        this.form.invoiceApplicationType == 'NO_PROJECT_NO_CONTRACT_NEEDED' ||
        this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED'
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    onRetroactive(key) {
      this.applyType = key
      this.isRetroactiveShow = true
    },
    shareholderNameFn(row) {
      this.form.shareholderName = row.userName
      this.form.shareholderId = row?.id
    },
    generalManagerNameFn(row) {
      this.form.generalManagerName = row.userName
      this.form.generalManagerId = row?.id
    },
    // 申请类型切换
    onChangeType(val) {
      if (val) {
        this.$refs.form.resetFields()
        const arr = [
          'id',
          'invoiceApplicationDetailList',
          'expenseNumber',
          'createName',
          'deptId',
          'taskDate',
          'detailDeleteList',
          'status',
          'statusName',
          'deptName',
          'createBy',
          'actStatus',
          'instInvolvedUserName',
          'isDisplayEditButton',
          'processInstId',
          'invoiceOffse',
          'offsetStatus',
          'offsetId',
          'invoicingNum',
          'affirmCostFileList',
          'sumCost',
          'taskDefinitionKey',
          'periods',
          'currentPeriods',
        ]
        Object.keys(this.form).forEach(key => {
          if (arr.indexOf(key) == -1) {
            this.form[key] = null
          }
        })
        this.form.invoiceApplicationType = val
        this.form.invoiceLines = 'NORMAL'
        this.agreementSelsectId = null
        this.projectSelsectId = null
        this.clearDetails()
        this.onChangeInvoiceLines('NORMAL')
      }
    },
    // 发票种类切换
    onChangeInvoiceLines(val) {
      if (val == 'NORMAL') {
        this.form.offsetId = null
        this.form.invoiceOffse = null
        this.expenseNumber = null
        this.offsetId = null
      }
    },
    // 冲红覆盖
    onCoverInvoice(row) {
      const data = row.deepClone()
      // 移除不需要覆盖值
      delete data.affirmCostFileList
      delete data.remark
      delete data.invoicingNum
      delete data.instInvolvedUserName
      delete data.expenseNumber
      data.invoiceApplicationDetailList.forEach(v => {
        delete v.id
        v.invoiceAmount = Number(v.invoiceAmount) * -1
      })
      // 保留旧数据唯一值
      data.invoiceLines = this.form.invoiceLines
      data.id = this.form.id
      data.status = this.form.status
      data.processInstId = this.form.processInstId
      this.form = data
      this.form.offsetId = row.id
      this.offsetId = row.id
      this.expenseNumber = row.expenseNumber

      this.isInvoiceShow = false
    },
    // 冲红不覆盖
    onCancelInvoice(row) {
      this.form.offsetId = row.id
      this.expenseNumber = row.expenseNumber
      this.offsetId = row.id
      this.isInvoiceShow = false
    },
    // 冲红取消
    offsetCancel() {
      this.isInvoiceShow = false
    },
    // 新增审核人
    selectAddAuditor(row) {
      this.$api.addAuditor
        .addTaskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 历史开票信息
    async historyIsShow() {
      if (!this.form.agreementId) {
        this.$message.warning('请先选择项目与合同')
      } else {
        this.historyDataList = []
        const res = await this.$api.invoice.historyDataList({ agreementId: this.form.agreementId })
        if (res.data) {
          this.historyDataList = res.data
        }
        this.history_show = true
      }
    },
    // 导出
    exportFn() {
      let time = new Date(this.$refs.OpinionArea?.opinionRecordList[0].auditDate).format(
        'yyyy-MM-dd'
      )
      let type = this.$refs.OpinionArea?.opinionRecordList[0].type
      let str = `${type}&${time}&${this.form.createName}&${this.amount}元`

      this.$api.invoice
        .exporting(this.form.id)
        .then(res => {
          const fileName = `${str}.xlsx`
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
            this.$message({
              message: '导出成功!',
              type: 'success',
            })
            document.body.removeChild(link) //下载完成移除元素
          }
        })
        .catch(e => {})
    },
    // 打印
    printFn() {
      this.$nextTick(() => {
        let arr = []
        const list = this.$refs.OpinionArea.opinionRecordList
        list.forEach(v => {
          if (v.isCheckPass == 1) {
            arr.push(v)
          }
        })

        let newArr = arr.reduce((filteredArr, item) => {
          const index = filteredArr.findIndex(el => el.step === item.step)
          if (index !== -1) {
            const existingItem = filteredArr[index]
            if (item.auditDate > existingItem.auditDate) {
              filteredArr[index] = item
            }
          } else {
            filteredArr.push(item)
          }
          return filteredArr
        }, [])
        let examine = {
          auditUserName: '',
          auditDate: null,
        }
        const step = newArr.find(v => v.step == '项目分管高管审批')
        if (step) {
          examine.auditUserName = step.auditUserName
          examine.auditDate = step.auditDate
        }
        this.examineInfo = examine
        this.printForm = this.form.deepClone()
        this.printDiaLog = true
      })
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = null
      this.imgList.forEach((v, i) => {
        if (v.id && n.id && v.id === n.id) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },
    // 明细保留二位小数
    blurInput(index) {
      this.form.invoiceApplicationDetailList.forEach((v, i) => {
        if (i === index) {
          v.invoiceAmount = Number(v.invoiceAmount).toFixed(2)
        }
      })
    },
    blurAmount(e) {
      this.form[e] = Number(this.form[e]).toFixed(2)
    },
    // 银行账号增加空格
    accountAddBlank(key) {
      this.form[key] = this.form[key].replace(/\s*/g, '')
      var result = []
      for (var i = 0; i < this.form[key].length; i++) {
        if (i % 4 == 0 && i != 0) {
          result.push(' ' + this.form[key].charAt(i))
        } else {
          result.push(this.form[key].charAt(i))
        }
      }
      this.form[key] = result.join('')
      this.$forceUpdate()
    },
    // 选择类型
    optionsChangeType(row) {
      if (row) {
        this.form.vatData = null
        this.form.vatRate = null
      }
    },
    // 选择税率
    optionsChange(v) {
      if (v[0]) {
        this.form.vatRate = v[0].vatType
      }
    },
    // 撤回
    rejectFn() {
      this.loading = true
      this.$api.invoice
        .reject({
          id: this.form.id,
        })
        .then(res => {
          this.$message({
            message: '撤回成功!',
            type: 'success',
          })
          this.loading = false
          this.re()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 审核取消
    cancelFn() {
      this.isDialog = false
      this.examineForm = {
        opinion: '',
        status: 0,
      }
    },
    /** 加载详情数据 **/
    getDetails() {
      this.loading = true

      this.$api.invoice
        .selectDetailById(this.options.id)
        .then(res => {
          this.form = res.data
          this.offsetId = res.data.offsetId || null
          this.form.detailDeleteList = []
          this.projectSelsectId = res.data.projectId || null
          this.agreementSelsectId = res.data.agreementId || null
          this.instInvolved = {
            instInvolvedUserName: res.data.instInvolvedUserName
              ? res.data.instInvolvedUserName
              : [],
            status: res.data.status,
          }
          this.agreementStageSelectedList = []
          this.form.invoiceApplicationDetailList.forEach(v => {
            this.agreementStageSelectedList.push({ id: v.agreementStagePlanId })
          })
          if (res.data.affirmCostFileList && res.data.affirmCostFileList.length > 0) {
            this.imgList = []
            res.data.affirmCostFileList.forEach(v => {
              if (!this.isShowType(v)) {
                this.imgList.push(v)
              }
            })
          }

          if (this.form.vatType && this.options.disabled) {
            this.$api.vat
              .getVatDataList({ pageNow: 1, pageSize: 99999 })
              .then(res => {
                if (res.data?.records) {
                  this.dictData.VatDataAll = res.data.records
                }
              })
              .catch(err => {
                console.log(err)
              })
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 点击 "添加期" 按钮 **/
    handleAdd() {
      if (
        this.form.invoiceApplicationDetailList &&
        this.form.invoiceApplicationDetailList.length >= 6
      ) {
        this.$message.warning('每张申请单最多只能添加6期开票')
        return
      }
      if (this.form.invoiceApplicationType == 'CONTRACT_SIGNED_NO_PROJECT_NEEDED') {
        if (!this.form.agreementName) {
          this.$message({
            type: 'warning',
            message: '请先选择或输入合同',
          })
          return
        }
        if (this.form.agreementId) {
          this.agreementStageList = []
          this.$api.agreement
            .listByAgreementId({ agreementId: this.form.agreementId })
            .then(res => {
              this.agreementStageList = res?.data
              if (this.agreementStageSelectedList && this.agreementStageSelectedList.length > 0) {
                this.agreementStageList = this.agreementStageList.map(item => ({
                  ...item,
                  Selected: this.agreementStageSelectedList.some(v => v.id === item.id) ? 1 : 0,
                }))
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
        this.agreement_show = true
      } else {
        this.form.invoiceApplicationDetailList.push({
          stageName: null,
          invoiceAmount: 0,
          paymentAmount: 0,
        })
      }
    },

    /**
     * 点击 "期数明细表格 -- 删除" 操作
     * @param index     下标值
     * @param row     当前行
     */
    deleteDetails(row, index) {
      if (row.id) {
        this.form.detailDeleteList.push(row.id)
      }
      this.form.invoiceApplicationDetailList.splice(index, 1)
      this.agreementStageSelectedList = this.agreementStageSelectedList.filter(
        v => v.id != row.agreementStagePlanId
      )
    },
    /** 1=提交 0=暂存 **/
    handleSubmit(status) {
      if (status == 1) {
        this.$refs.form.validate((valid, object) => {
          if (valid) {
            if (this.verifyFn()) return
            if (this.form.invoiceApplicationDetailList.some(v => Number(v.invoiceAmount) === 0)) {
              this.$message({
                type: 'warning',
                message: '阶段开票金额不能为0',
              })
              return
            }
            if (this.form.invoiceApplicationDetailList.some(v => !v.stageName)) {
              this.$message({
                type: 'warning',
                message: '阶段名称不能为空',
              })
              return
            }
            this.loading = true
            this.submitDialog = true
            this.loading = false
          } else {
            if (this.$refs) {
              formLocationError(object, this.$refs)
              return false
            }
          }
        })
      } else if (status == 0) {
        let saveKey = ''
        if (this.form.id) {
          saveKey = 'undeterminedEdit'
        } else {
          saveKey = 'tempAdd'
        }
        this.$refs.form.validate((valid, object) => {
          if (valid) {
            if (this.verifyFn()) return
            this.loading = true
            this.$api.invoice[saveKey](this.form)
              .then(res => {
                if (this.form.id) {
                  this.$message({
                    message: '编辑成功',
                    type: 'success',
                  })
                } else {
                  this.$message({
                    message: '暂存成功',
                    type: 'success',
                  })
                }

                this.loading = false
                this.re()
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          } else {
            if (this.$refs) {
              formLocationError(object, this.$refs)
              return false
            }
          }
        })
      }
    },
    // 提交意见
    submitOpinionFn(opinion) {
      this.loading = true
      let submitKey = ''
      // 状态为 5并且有流程ID 是驳回，有流程ID并且状态为0 是撤回
      if (
        (this.form.processInstId && this.form.status == '5') ||
        (this.form.processInstId && this.form.status == 0)
      ) {
        submitKey = 'resubmitByReject'
      } else {
        submitKey = 'startProcess'
      }
      this.loading = true
      this.$api.invoice[submitKey]({ ...this.form, opinion })
        .then(res => {
          this.$message({
            message: '提交成功',
            type: 'success',
          })

          this.loading = false
          this.re()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.submitDialog = false
    },

    /** 点击 "通过" 按钮 **/
    handleAdopt() {
      this.examineForm.status = 1
      this.isDialog = true
    },

    /** 点击 "不通过" 按钮 **/
    handleFail() {
      this.examineForm.status = 2
      this.isDialog = true
    },

    /**
     * 审批 "发票" 申请单
     * @param status     状态 [ 1: 通过, 2: 不通过 ]
     */
    callApproval(status) {
      let _obj = {
        id: this.form.id,
        opinion: this.examineForm.opinion,
      }
      this.loading = true
      if (status == 1) {
        _obj.opinion = this.examineForm.opinion ? this.examineForm.opinion : '通过'
        _obj.processInstId = this.form.processInstId
        this.$api.addAuditor
          .disposeTask(_obj)
          .then(res => {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
            this.loading = false
            // this.$parent.getTableData()
            this.re()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else if (status == 2) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$api.invoice
              .approvalReject(_obj)
              .then(res => {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                })
                this.loading = false
                // this.$parent.getTableData()
                this.re()
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    // 选择项目
    choiceProject(r) {
      this.selectedProjectNameList = [r]
      this.form.projectId = r.id
      this.form.projectName = r.projectName //项目名
      this.form.deptClassifyName = r.deptClassifyName //所属部门名称
      this.form.deptClassifyId = r.deptClassifyId //所属部门id
      this.form.fzManagerName = r.deptFzManagerName //分管高管
      this.form.deptFzManagerId = r.deptFzManagerId //分管高管id
      this.form.projectType = r.projectType //项目级类型
      this.form.projectSource = r.projectSource //项目来源
      this.form.vatType = r.vatType //增值税类型
      this.form.vatData = r.vatData //增值税详情
      this.form.vatRate = r.vatRate //增值税税率
      this.form.agreementId = null
      this.form.agreementName = null
      this.form.agreementNum = null
      this.form.customersUnit = null
      this.form.companyType = null
      this.form.actualContractAmount = null
      this.agreementStageList = [] //期数弹窗
      this.agreementStageSelectedList = [] //期数弹窗选中
      this.projectSelsectId = r.id
      this.agreementSelsectId = null //合同选中
      // this.form.currentPeriods = null //开票次数
      // this.form.periods = null //开票期数
      this.$forceUpdate()
      this.clearDetails()
    },
    choiceContract(r) {
      this.agreementSelsectId = r.id
      this.form.agreementId = r.id
      this.form.agreementName = r.agreementName
      this.form.agreementNum = r.agreementNum
      this.form.actualContractAmount = r.actualContractAmount //合同金额
      this.agreementStageList = r.agreementStageList //该合同有几期供填报人选择
      // this.form.currentPeriods = r.currentPeriods
      this.form.customersUnit = r.customersUnit
      this.form.companyType = r.companyType
      // this.form.periods = r.periods
      this.agreementStageSelectedList = []
      // 有合同未立项（不需要不立项）
      if (this.agreementRules) {
        this.form.projectName = r.agreementName
      }
      this.$forceUpdate()
      this.clearDetails()
    },

    getDict() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE_FULL', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('OFFSET_STATUS', true)
        .then(res => {
          this.dictData.offsetStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_LINES', true)
        .then(res => {
          this.dictData.invoiceLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_TYPE', true)
        .then(res => {
          this.dictData.invoiceType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE_COLLECT', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('INVOICE_APPLICATION_TYPE', true)
        .then(res => {
          this.dictData.invoiceApplicationType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    checkPhase(r, index) {
      this.form.invoiceApplicationDetailList = this.form.invoiceApplicationDetailList
        ? this.form.invoiceApplicationDetailList
        : []
      this.form.invoiceApplicationDetailList.push({
        // numberNo: index + 1, //期数
        paymentAmount: r.paymentAmount, //阶段金额
        stageName: r.stageName, //阶段
        paymentStandard: r.paymentStandard, //阶段内容
        agreementStagePlanId: r.id, //阶段id
        invoiceAmount: 0,
      })
      this.agreementStageSelectedList.push(r)
      this.agreement_show = false
    },
    // 通用校验
    verifyFn() {
      if (this.form.invoiceLines === 'OFFSET' && !this.form.offsetId) {
        this.$message({
          type: 'warning',
          message: '当前开票种类为红字发票（冲红），请选择需要冲红单据',
        })
        return true
      }
      if (!this.form.invoiceApplicationDetailList?.length) {
        this.$message({
          type: 'warning',
          message: '请添加开票明细',
        })
        return true
      }
      if (
        this.form.invoiceLines === 'OFFSET' &&
        this.form.invoiceApplicationDetailList.some(v => Number(v.invoiceAmount) >= 0)
      ) {
        this.$message({
          type: 'warning',
          message: '当前开票种类为红字发票（冲红），开票金额必须为负数',
        })
        return true
      }
    },
    clearDetails() {
      if (
        this.form.invoiceApplicationDetailList &&
        this.form.invoiceApplicationDetailList.length > 0
      ) {
        this.form.detailDeleteList = []
        this.form.invoiceApplicationDetailList.forEach(v => {
          if (v.id) {
            this.form.detailDeleteList.push(v.id)
          }
        })
        this.form.invoiceApplicationDetailList = []
      }
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
}
</script>

<style scoped lang="scss">
.fullScreenMainCon {
  overflow: hidden;
  padding: 25px 0;
}

.form_box {
  .danwei {
    position: absolute;
    right: 2%;
  }

  .aiw {
    width: calc(100% - 33.3333%);
  }

  .amount {
    display: inline-block;
    width: 100%;
    height: 40px;
    color: #c0c4cc;
    background-color: #f5f7fa;
    padding: 0 10px;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
  }
}

.ticket_box {
  margin-top: 16px;

  .ticket_div {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    span {
      display: inline-block;
      margin: 0 4px;
      text-decoration: underline;
    }

    .section {
      width: 60px;
      text-align: center;
    }

    .lineBtn {
      float: right;
      margin-top: 10px;
    }
    .jilvBtn {
      float: right;
      margin-left: 8px;
      margin-top: 10px;
    }
  }
}

.el-divider__text.is-left {
  font-size: 18px;
}

.margin-top {
  margin-top: 10px;
}

.w_100 {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
  padding: 0;
}
.el-descriptions .el-descriptions-item__cell {
  padding-bottom: 7px;
}
/deep/.invoiceLabelClassName {
  width: 12.5%;
}
/deep/.invoiceContentClassName {
  height: 75px !important;
}

// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}

.invoice-file-text {
  display: inline-block;
  width: 300px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #c0c4cc !important;
  cursor: not-allowed !important;
}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 0px;
}
</style>
